import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../constants";
import {formatNumberWithCommas} from "../helpers";
import Avatar from "./Avatar";
import gameIcon from '../assets/game-icon.png';
import tgIcon from '../assets/tg-icon.png';
import xIcon from '../assets/x-icon.png';

const INITDATA_HEADER = { 'ngrok-skip-browser-warning': '69420' };
const DISPLAY_REWARDS_PER_PAGE = 5;

const pointTypeMapping = {
  'vote-candidate': { title: 'Voting', label: 'Voting Points', icon: gameIcon },
  'team-selection': { title: 'Voting', label: 'Voting Points', icon: gameIcon },
  'x-vote': { title: 'X Tweet', label: 'Tweet Election', icon: xIcon },
  'x-subscription': { title: 'X Follow', label: 'X Follow Points', icon: xIcon },
  'game': { title: 'Game', label: 'Game Points', icon: gameIcon },
  'telegram-subscription': { title: 'TG Follow', label: 'TG Follow Points', icon: tgIcon },
  'referral': { title: null, label: 'Referral Points', icon: null },
  'sign-up': { title: null, label: 'Sign-Up Points', icon: null },
};

const PointHistory = () => {
  const [rewards, setRewards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const initData = window.Telegram?.WebApp?.initData;
  
  const getRewards = async (initData, page) => {
    try {
      const res = await axios.request({
        baseURL: API_URL,
        url: 'telegram-users/rewards',
        headers: { initdata: initData, ...INITDATA_HEADER },
        params: { page, take: DISPLAY_REWARDS_PER_PAGE }
      });
      setRewards(res.data.data);
      setPageCount(res.data.meta.pageCount);
    } catch (e) {
      console.error("Error fetching rewards data:", e);
    }
  };

  const handleDotClick = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getRewards(initData, currentPage);
  }, [initData, currentPage]);

  const typesWithIcon = new Set([
    'x-subscription',
    'x-vote',
    'game',
    'vote-candidate',
    'telegram-subscription',
    'team-selection',
  ]);

  const typesWithIconOrReferral = new Set([
    ...typesWithIcon,
    'referral',
    'sign-up'
  ]);

  const getPointsTypeClass = (type) => (
    typesWithIconOrReferral.has(type) ? 'points-type' : 'points-type with-padding'
  );

  const getPointsColSpan = (type) => (
    typesWithIconOrReferral.has(type) ? 1 : 2
  );

  const Reward = ({ index, username, firstName, lastName, type, amount, telegramId, team, gamesPlayed }) => {
    const name = username
      ? `@${username}`
      : [firstName, lastName].filter(Boolean).join(' ');
    const pointType = pointTypeMapping[type] || { label: `${type} Points`, icon: null };


    return (
      <tr key={name}>
        {(type === 'referral' || type === 'sign-up') && (
          <td className='username'>
            <div className='username-container'>
              {name && <>
                <Avatar
                  firstName={firstName}
                  lastName={lastName}
                  team={team}
                  size={25}
                  username={username}
                  userId={telegramId}
                />
                {name}
              </>
              }
            </div>
          </td>
        )}

        {(typesWithIcon.has(type)) && (
          <td className='username'>
            <div className='username-container'>
              {pointType.icon && <img className='round-avatar' src={pointType.icon} alt={type}/>}
              {type === 'game' ?
                `${pointType.title}: ${formatNumberWithCommas(gamesPlayed)}` : pointType.title}
            </div>
          </td>
        )}

        <td
          className={getPointsTypeClass(type)}
          colSpan={getPointsColSpan(type)}
        >
          {pointType.label}
        </td>
        <td className='points-count'>+{formatNumberWithCommas(amount)}</td>
      </tr>
    );
  };

  return (
    <div className='point-history-container'>
      <table>
        <thead>
        <tr>
          <td colSpan={3}>My Point History</td>
        </tr>
        </thead>
        <tbody>
        {rewards.map(({username, firstName, lastName, type, amount, telegramId, team, gamesPlayed}, index) =>
          <Reward
            key={`${type}-${index}`}
            index={index}
            username={username}
            firstName={firstName}
            lastName={lastName}
            type={type}
            amount={amount}
            telegramId={telegramId}
            team={team}
            gamesPlayed={gamesPlayed}
          />
        )}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan={3}>
            <div className="dots-pagination-container">
              {[...Array(pageCount)].map((_, index) => (
                <div
                  key={index}
                  className={`dot ${currentPage === index + 1 ? 'active' : ''}`}
                  onClick={() => handleDotClick(index + 1)}
                  style={{cursor: 'pointer'}}
                ></div>
              ))}
            </div>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default PointHistory;
