export const formatNumberWithCommas = (number) => number?.toLocaleString('en-US');

export const formatPercentage = (num) => {
  return num % 1 === 0 ? `${num}%` : `${parseFloat(num.toFixed(2))}%`;
}

export const parsePercentage = (percentage) => Math.round(parseFloat(percentage));

export const formatValue = (title, value) => {
  if (typeof value === 'number') {
    if (title === "Playing Time") {
      return `${value}  ${value === 1 ? "Day" : "Days"}`;
    }

    if (title.includes("Score") || title.includes("Purse")) {
      return `${value} ${value === 1 ? "Pt" : "Pts"}`;
    }
  }
  return value;
};

export const tweet = (gameUrl, userId, generatedUserId, navigate) => {
  const tweetText = encodeURIComponent(
    `Think you know the next US President? 🗳️ Cast your vote, earn up to 30,000 points, and watch the grand prize grow with every vote cast!\n\nVote and Earn Now! ${gameUrl}?startapp=ref_${userId}\n\n@Whackem_TON @realDonaldTrump @KamalaHarris @ton_blockchain #Airdrop #Whackem_TON\n\n${generatedUserId}`
  );
  const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
  window?.Telegram?.WebApp?.openLink(tweetUrl);

  if (navigate) {
    navigate('/tabs');
  }
};

export const getDisplayName = (firstName, lastName, name) => {
  return (firstName || lastName)
    ? [firstName, lastName].filter(Boolean).join(' ')
    : `@${name}`;
};

export const getPageCount = (totalCount, take) => {
  return Math.ceil(totalCount / take);
}
