import React, {useEffect} from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ReactGA from 'react-ga4';
import {GOOGLE_ANALYTICS_ID} from "./constants";
import './App.css';
import FlashScreen from './pages/FlashScreen';
import SubscribeScreen from './pages/SubscribeScreen';
import CongratulationsScreen from './pages/CongratulationsScreen';
import SelectParty from "./pages/SelectParty";
import GameScreen from "./pages/GameScreen";
import Tabs from "./components/Tabs";
import ScoresScreen from "./pages/ScoresScreen";
import EarnPointsScreen from "./pages/EarnPointsScreen";
import AccountScreen from "./pages/AccountScreen";
import WalletScreen from "./pages/WalletScreen";
import BetWinScreen from "./pages/BetWinScreen";
import SeedIfoScreen from "./pages/SeedIfoScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <FlashScreen/>,
  },
  {
    path: "/subscribe",
    element: <SubscribeScreen/>,
  },
  {
    path: "/congratulations",
    element: <CongratulationsScreen/>,
  },
  {
    path: "/select-party",
    element: <SelectParty/>,
  },
  {
    path: "/tabs",
    element: <Tabs />,
    children: [
      {
        path: "",
        element: <Navigate to="game" replace />,
      },
      {
        path: "game",
        element: <GameScreen />,
      },
      {
        path: "high-scores",
        element: <ScoresScreen />,
      },
      {
        path: "earn-points",
        element: <EarnPointsScreen />,
      },
      {
        path: "account",
        element: <AccountScreen />,
      },
      {
        path: "wallet",
        element: <WalletScreen />,
      },
      {
        path: "bet-win",
        element: <BetWinScreen />,
      },
      {
        path: "seed-ifo",
        element: <SeedIfoScreen />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

const App = () => {
  useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  }, []);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default App;
