import React, {useEffect, useRef, useState} from "react";
import {getVoteGrandPrize, getVoteStats} from "../services";
import {formatNumberWithCommas, parsePercentage} from "../helpers";
import Statistics from "../components/Statistics";
import Preloader from "../components/Preloader";
import redTeam from "../assets/trump-photo.png";
import blueTeam from "../assets/harris-photo.png";
import {useNavigate} from "react-router-dom";

const BetWinScreen = () => {
  const [votedTeam, setVotedTeam] = useState('');
  const [actualGrandPrize, setActualGrandPrize] = useState(0);
  const [userVote, setUserVote] = useState(null);
  const [redPercentage, setRedPercentage] = useState(0);
  const [bluePercentage, setBluePercentage] = useState(0);
  const [myPurse, setMyPurse] = useState(null);
  const [castedVotes, setCastedVotes] = useState(0);
  const [referrals, setReferrals] = useState(0);
  const [showStatistics, setShowStatistics] = useState(false);
  const [loading, setLoading] = useState(false);
  const statisticsRef = useRef(null);
  const navigate = useNavigate();

  const fetchAndUpdateData = async () => {
    setLoading(true);
    const voteData = await getVoteGrandPrize();
    const isVote = voteData.isVote;

    if (!voteData.isVote) {
      navigate('/select-party');
      return;
    }

    setActualGrandPrize(voteData.actualGrandPrize);
    setRedPercentage(voteData.redPercentage);
    setBluePercentage(voteData.bluePercentage);

    if (isVote) {
      try {
        const voteStatsData = await getVoteStats();
        setUserVote(voteStatsData.userVote);
        setVotedTeam(voteStatsData.userVote || '');
        setRedPercentage(voteStatsData.redPercentage);
        setBluePercentage(voteStatsData.bluePercentage);
        setMyPurse(voteStatsData.myPurse);
        setCastedVotes(voteStatsData.castedVotes);
        setReferrals(voteStatsData.referrals);
      } catch (error) {
        console.error("Error fetching vote stats:", error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAndUpdateData();
  }, []);

  useEffect(() => {
    if (showStatistics && statisticsRef.current) {
      statisticsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showStatistics]);

  const handleDetailsClick = () => {
    setShowStatistics(!showStatistics);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
      <div className="bet-win-container">
        <div className="bet-win-inner-wrap">
          <div className="bet-win">
            <div className="select-party-title-wrap">
              {showStatistics ? (
                <p>Live Voting Ballot</p>
              ) : (
                <p>Vote: us presidential election 2024</p>
              )}
            </div>
            <div className="bet-win-content">
            <h1 className="title">Grand Prize</h1>
              <p className="points">{formatNumberWithCommas(actualGrandPrize)} Points</p>
              <div className="team-card-wrap">
                <button
                  className={`team-card ${votedTeam === 'red' ? 'red' : ''} ${userVote !== null ? 'no-cursor' : ''}`}
                >
                  <div className="image-container">
                    <img src={redTeam} alt="Red Team"/>
                  </div>
                  <h2>Trump</h2>
                  <span>{parsePercentage(redPercentage)}%</span>
                </button>
                <button
                  className={`team-card ${votedTeam === 'blue' ? 'blue' : ''} ${userVote !== null ? 'no-cursor' : ''}`}
                >
                  <div className="image-container">
                    <img src={blueTeam} alt="Blue Team"/>
                  </div>
                  <h2>Harris</h2>
                  <span>{parsePercentage(bluePercentage)}%</span>
                </button>
              </div>
              {userVote !== null && !showStatistics && (
                <button
                  className="bet-win-btn purple"
                  onClick={handleDetailsClick}
                >
                  <span>
                    Details
                  </span>
                </button>
              )}
            </div>
          </div>
          {userVote !== null && showStatistics && (
            <div ref={statisticsRef}>
              <Statistics
                stats={[
                  { title: "My Purse", value: formatNumberWithCommas(Math.round(myPurse)), icon: "award" },
                  { title: "Casted Votes", value: formatNumberWithCommas(Math.round(castedVotes)), icon: "play" },
                  { title: "Referrals", value: formatNumberWithCommas(Math.round(referrals)), icon: "trend-up" },
                  { title: "Election Day", value: "Nov 5, 2024", icon: "clock" }
                ]}
              />
            </div>
          )}
        </div>
      </div>
  );
};

export default BetWinScreen;
