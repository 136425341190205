import axios from "axios";
import {API_URL, TELEGRAM_BOT_TOKEN} from "../constants";

const initData = window.Telegram?.WebApp?.initData;
const TELEGRAM_API_URL = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}`;

export const getGameStats = async () => {
  try {
    const {data} = await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/game/stats',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const getExtraInformation = async () => {
  try {
    const {data} = await axios.request({
      baseURL: API_URL,
      url: 'telegram-users/extra-information',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      }
    });
    return data;
  } catch (e) {
    console.error("Error fetching balance data:", e);
  }
};

export const getVoteStats = async () => {
  try {
    const {data} = await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/vote/stats',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const getTwitterVote = async () => {
  try {
    await axios.request({
      baseURL: API_URL,
      url: 'telegram-users/x-vote',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
  } catch (e) {
    console.error("Error fetching balance data:", e);
  }
}

export const getTwitterFollow = async () => {
  try {
    await axios.request({
      baseURL: API_URL,
      url: 'telegram-users/x-follow',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
  } catch (e) {
    console.error("Error fetching balance data:", e);
  }
}

export const getVoteGrandPrize = async () => {
  try {
    const {data} = await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/vote/grand-prize',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const fetchScores = async (page, take) => {
  try {
    return await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/game/high-scores',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
      params: {page, take}
    });
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const getUserPhoto = async (userId) => {
  try {
    const response = await axios.get(`${TELEGRAM_API_URL}/getUserProfilePhotos`, {
      params: {
        user_id: userId,
        limit: 1,
      },
    });
    const data = response.data.result.photos;

    if (data && data.length > 0) {
      const fileId = data[0][0].file_id;
      const filePathResponse = await axios.get(`${TELEGRAM_API_URL}/getFile`, {
        params: {
          file_id: fileId,
        },
      });

      const filePath = filePathResponse.data.result.file_path;
      return `https://api.telegram.org/file/bot${TELEGRAM_BOT_TOKEN}/${filePath}`;
    }
  } catch (error) {
    console.error('Error fetching user profile photos:', error);
    throw error;
  }
}
