import React, {useEffect, useState} from "react";
import axios from "axios";
import useUserStore from "../store/userStore";
import {API_URL, telegramLink, twitterLink} from "../constants";
import {getExtraInformation, getTwitterFollow, getTwitterVote} from "../services";
import {formatNumberWithCommas, tweet} from "../helpers";
import ButtonLarge from "../components/ButtonLarge";
import Preloader from "../components/Preloader";
import earnPointsData from "../assets/earn-points.json";
import sprite from "../assets/sprite.svg";

const EarnPointsScreen = () => {
  const {user, fetchUserOrCreate} = useUserStore();
  const [earnPoints, setEarnPoints] = useState([]);
  const [isUserSubscribedOnTelegram, setIsUserSubscribedOnTelegram] = useState(false);
  const [isUserSharedOnX, setIsUserSharedOnX] = useState(null);
  const [isUserSubscribedOnX, setIsUserSubscribedOnX] = useState(null);
  const [loading, setLoading] = useState(true);
  const initData = window.Telegram?.WebApp?.initData;

  const gameUrl = process.env.REACT_APP_GAME_URL;
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const text = "Join the Whack’em TON Mini Game and claim yourself a FREE bag of 20,000 Airdrop Points instantly! Join now and have a chance to get your slice of the Grand Prize that is now over 20 MILLION Airdrop Points!";
  const encodedText = encodeURIComponent(text);
  const fullUrl = `${gameUrl}?startapp=ref_${userId}`;

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }
    })();
  }, [fetchUserOrCreate, initData]);

  useEffect(() => {
    (async () => {
      const extraInformationData = await getExtraInformation();
      setIsUserSubscribedOnTelegram(extraInformationData.isUserSubscribedOnTelegram);
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
      setIsUserSubscribedOnX(extraInformationData.isUserFollowOnX);
      setLoading(false);
    })();

    setEarnPoints(earnPointsData);
  }, [isUserSharedOnX, isUserSubscribedOnX]);

  const handleTweetVote = async () => {
    if (user) {
      tweet(gameUrl, userId, user.generatedUserId);
      await getTwitterVote();
      const extraInformationData = await getExtraInformation();
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
    }
  };

  const handleFollowX = async () => {
    window?.Telegram?.WebApp?.openLink(twitterLink);
    await getTwitterFollow();
    const extraInformationData = await getExtraInformation();
    setIsUserSubscribedOnX(extraInformationData.isUserFollowOnX);
  };

  const handleClaimClick = async (id, platform) => {
    if (platform !== "refer") {
      try {
        const response = await axios.request({
          method: 'POST',
          baseURL: API_URL,
          url: 'telegram-users/generate-social-media-reward',
          data: { platform },
          headers: {
            initdata: initData,
            'ngrok-skip-browser-warning': '69420'
          },
        });
        if (response.data.success) {
          const extraInformationData = await getExtraInformation();

          switch(platform) {
            case "telegram":
              window?.Telegram?.WebApp?.openTelegramLink(telegramLink);
              break;
            case "x-vote":
              setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
              break;
            case "twitter":
              setIsUserSubscribedOnX(extraInformationData.isUserSubscribedOnX);
              break;
            default:
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  if (!earnPoints || loading) {
    return <Preloader />;
  }

  return (
    <div className="earn-points-container">
      <ul>
        {earnPoints.map((item) => (
          (item.platform !== 'twitter' || (isUserSharedOnX !== null && isUserSharedOnX !== 'pending')) && (
            <li
              key={item.id}
              className="earn-points-item"
            >
              <div className="earn-points-bar">
                <img src={item.icon} alt="icon"/>
              </div>
              <div className="earn-points-content">
                <h3 className="earn-points-title">{item.title}</h3>
                <span className="earn-points-value" data-content={`+${formatNumberWithCommas(item.points)} Pts`}>
                  +{formatNumberWithCommas(item.points)} Pts
                </span>
                {item.platform === 'telegram' && (
                  <ButtonLarge
                    onClick={() => handleClaimClick(item.id, item.platform)}
                    additionalClass={isUserSubscribedOnTelegram ? "button-done" : ""}
                  >
                    {isUserSubscribedOnTelegram ? 'Done' : item.buttonTitle}
                  </ButtonLarge>
                )}

                {item.platform === 'x-vote' && (
                  isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
                    <ButtonLarge
                      onClick={() => handleClaimClick(item.id, item.platform)}
                      additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
                    >
                      {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
                    </ButtonLarge>
                  ) : (
                    <div className="button-wrap">
                      <ButtonLarge
                        onClick={handleTweetVote}
                        additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
                      >
                        {isUserSharedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                      </ButtonLarge>
                      <div className="earn-points-msg">
                        <svg height="20" width="20">
                          <use href={`${sprite}#alert`}></use>
                        </svg>
                        {isUserSharedOnX === 'pending' ? (
                          <p>Credit can take up to 70 minutes</p>
                        ) : (
                          <p>Modifying the Tweet may delay your points credit or make you ineligible to receive them</p>
                        )}
                      </div>
                    </div>
                  )
                )}

                {item.platform === 'twitter' && (
                  isUserSubscribedOnX === 'claim' || isUserSubscribedOnX === 'done' ? (
                    <ButtonLarge
                      onClick={() => handleClaimClick(item.id, item.platform)}
                      additionalClass={isUserSubscribedOnX === "done" ? "button-done" : ""}
                    >
                      {isUserSubscribedOnX === 'claim' ? 'Claim' : 'Done'}
                    </ButtonLarge>
                  ) : (
                    <div className="button-wrap">
                      <ButtonLarge
                        onClick={handleFollowX}
                        additionalClass={isUserSubscribedOnX === 'pending' ? 'button-pending' : ''}
                        disabled={isUserSharedOnX === null || isUserSharedOnX === 'pending'}
                      >
                        {isUserSubscribedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                      </ButtonLarge>
                      {isUserSubscribedOnX === 'pending' && (
                        <div className="earn-points-msg">
                          <svg height="20" width="20">
                            <use href={`${sprite}#alert`}></use>
                          </svg>
                          <p>Credit can take up to 70 minutes</p>
                        </div>
                      )}
                    </div>
                  )
                )}

                {item.platform === 'refer' && (
                  <a href={`https://t.me/share/url?url=${encodeURIComponent(fullUrl)}&text=${encodedText}`}>
                    <ButtonLarge>Refer Friends</ButtonLarge>
                  </a>
                )}
              </div>
            </li>
          )
        ))}
      </ul>
    </div>
  );
};

export default EarnPointsScreen;
